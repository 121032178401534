<template>
  <div class="news">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="kind">
            <a href="javascript:;" :class="activeClass == index ? 'active':''" v-for="(item,index) in common.news_type" :key="index" @click="news_type(item.id); click_news_type(index);">{{item.name}}</a>
          </div>
          <swiper :options="swiperOption" class="hots">
            <swiper-slide
              class="flex hot"
              v-for="(item, index) in common.top_news"
              :key="index"
            >
              <img v-bind:src="item.img" alt="" />
              <div class="new-info">
                <div class="new-time">{{item.add_time}}</div>
                <div class="new-dt">
                  <a v-bind:href="item.url">
                    {{item.title}}</a
                  >
                </div>
                <div class="new-dd">
                  {{item.info}}
                </div>
                <div class="new-a"><a v-bind:href="item.url">查看详情</a></div>
              </div>
            </swiper-slide>
            <!-- 分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <ul class="news-list flex wrap">
            <li v-for="(item, index) in common.news" :key="index">
              <img v-bind:src="item.img" alt="" />
              <div class="new-info">
                <div class="new-time">{{item.add_time}}</div>
                <div class="new-dt">
                  <a v-bind:href="item.url">
                    {{item.title}}</a
                  >
                </div>
                <div class="new-dd">
                  {{item.info}}
                </div>
                <div class="new-a"><a v-bind:href="item.url">查看详情</a></div>
              </div>
            </li>
          </ul>
          <div class="pagination flex center">
            <a href="javascript:;" @click="page('up');"><span class="iconfont icon-left"></a>
            <a href="javascript:;" v-for="(item,index) in common.page" :key="index" @click="page(item.p);">{{item.p_name}}</a>
            <a href="javascript:;" @click="page('down');"><span class="iconfont icon-you"></a>
          </div>
          <input type="hidden" ref="page" v-model="common.n_p">
          <input type="hidden" ref="type" v-model="common.n_t">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "news",
  props: {
    msg: String,
  },
  data() {
    return {
		activeClass: 0,
		common: [],
      height: 0,
      now: 0,
      swiperOption: {
        slidesPerView: "2",
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  mounted() {
this.$http.post('news.php',Qs.stringify({data_type:'common'}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 4) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
news_data(id, page){
	this.$http.post('news.php',Qs.stringify({data_type:'dtype', id:id, page:page}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
		this.common.top_news=response.data.top_news;
		this.common.news=response.data.news;
		this.common.page=response.data.page;
		this.common.n_p=response.data.n_p;
	})
},
news_type(id) {
	this.common.n_t=id;
	this.$options.methods.news_data.bind(this)(id, 1);
},
click_news_type(index){
	this.activeClass = index;
},
page(page){
	this.common.n_p=parseInt(this.common.n_p);
	if (page=='up'){
		page=this.common.n_p-1;
	}
	if (page=='down'){
		page=this.common.n_p+1;
	}
	this.$refs.page.value=page;
	this.$options.methods.news_data.bind(this)(this.common.n_t, page);
},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.news {
  .hots {
    margin-bottom: 4rem;
    padding-bottom: 6rem;
    .hot {
      padding: 3rem;
      border: 1px solid #ccc;
      box-sizing: border-box;
      & > img {
        width: 33rem;
        height: 18.6rem;
        object-fit: cover;
      }
      .new-info {
        width: 34rem;
      }
    }
  }

  .news-list {
    li {
      width: 49rem;
      box-sizing: border-box;
      padding: 5rem 5rem 4rem;
      border: 1px solid #ccc;
      margin-bottom: 2.5rem;
      &:hover {
        border-color: #8f0001;
      }
      & > img {
        width: 100%;
        height: 19.7rem;
        object-fit: cover;
        margin-bottom: 3rem;
      }
    }
  }
  .new-info {
    .new-time {
      font-size: 1.6rem;
      color: #999999;
      margin-bottom: 1rem;
    }
    .new-dt {
      font-size: 2.4rem;
      line-height: 3.5rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      margin-bottom: 3rem;
      &:hover {
        color: #8f0001;
      }
    }
    .new-dd {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #666666;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      margin-bottom: 3rem;
    }
    .new-a {
      color: #8f0001;
    }
  }
  @media screen and (max-width: 640px) {
    .hots .hot {
      padding: 2%;
      & > img {
        width: 100%;
        height: 12rem;
        margin-bottom: 3rem;
      }
    }
    .news-list li {
      width: 50%;
      padding: 2%;
      margin-bottom: 0;
      & > img {
        height: 12rem;
      }
    }
  }
}
</style>
