<template>
  <div class="about">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="home">
            <h3>{{common.s1.title}}</h3>
            <p>{{common.s1.stitle}}</p>
          </div>
          <div v-html="common.s1.info">{{common.s1.info}}</div>
        </div>
      </div>
    </transition>
    <div class="line"></div>
    <transition name="fadeInLeft">
      <div class="bg" v-show="now > 2">
        <div class="cont">
          <div class="home">
            <h3>{{common.s2.title}}</h3>
            <p>{{common.s2.stitle}}</p>
          </div>
          <div class="history">
            <swiper :options="swiperOption3">
              <template v-for="(item, index) in common.s2.img">
                <swiper-slide
                  class="swiper-slide his-item"
                  :key="index"
                  v-if="index % 2 === 0"
                >
                  <div class="flex">
                    <div class="his-time">
                      <span>{{ item.name }}</span
                      ><img src="@/assets/x1.png" alt="" />
                    </div>
                    <div class="his-text">
                      <div class="his-dt">{{ item.name }}年</div>
                      <div class="his-dd">{{ item.other }}</div>
                    </div>
                  </div>
                  <div class="flex sp">
                    <div class="his-time">
                      <img src="@/assets/x2.png" alt="" />
                      <span>{{ common.s2.img[index + 1].name }}</span>
                    </div>
                    <div class="his-text">
                      <div class="his-dt">{{ common.s2.img[index + 1].name }}年</div>
                      <div class="his-dd">{{ common.s2.img[index + 1].other }}</div>
                    </div>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
          <div class="long"></div>

          <div class="funs">
            <img src="@/assets/oh.jpg" class="bg-img" alt="" />
            <ul class="fun flex">
              <li v-for="(item, index) in common.s3.img" :key="index">
                <img v-bind:src="item.img" alt="" />
                <p>{{item.name}}</p>
              </li>
            </ul>
          </div>
          <div class="long"></div>
          <transition name="bounceIn">
            <div v-show="now > 3">
              <div class="home">
                <h3>{{common.s4.title}}</h3>
                <p>{{common.s4.stitle}}</p>
              </div>
              <swiper :options="swiperOption" class="prize">
                <swiper-slide
                  class="swiper-slide"
                  v-for="(item, index) in common.s4.img"
                  :key="index"
                >
                  <img v-bind:src="item.img" style="width: 100%" alt="" />
                </swiper-slide>
                <div
                  class="swiper-pagination pagination2"
                  slot="pagination"
                ></div>
              </swiper>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="rubberBand">
      <div class="bg" v-show="now > 4">
        <img src="@/assets/bg2.jpg" class="bg-img" alt="" />
        <div class="cont">
          <div class="home white">
            <h3>{{common.s5.title}}</h3>
            <p>{{common.s5.stitle}}</p>
          </div>
          <swiper :options="swiperOption2" class="shopping">
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in common.s5.img"
              :key="index"
            >
              <img v-bind:src="item.img" style="width: 100%" alt="" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </transition>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 5">
        <div class="cont">
          <div class="home">
            <h3>{{common.s6.title}}</h3>
            <p>{{common.s6.stitle}}</p>
          </div>
          <ul class="skill flex wrap">
            <li v-for="(item, index) in common.s6.img" :key="index">
              <img v-bind:src="item.img" alt="" />
              <p>{{item.name}}</p>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "about",
  props: {
    msg: String,
  },
  data() {
    return {
		common: [],
      now: 0,
      height: 0,
      swiperOption: {
        loop: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "2",
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 200,
          modifier: 3,
          slideShadows: true,
        },
        pagination: {
          el: ".pagination2",
        },
      },
      swiperOption2: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        slidesPerView: 1,
      },
      swiperOption3: {
        loop: false,
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于320
          320: {
            slidesPerView: 2,
          },
          //当宽度大于等于480
          480: {
            slidesPerView: 2,
          },
          //当宽度大于等于640
          640: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  mounted() {
this.$http.post('statics.php',Qs.stringify({data_type:'common', type_id:this.$route.query.type_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 16) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.about {
  .profile {
    font-size: 2rem;
    line-height: 3.6rem;
    text-indent: 2rem;
    img {
      max-width: 100%;
      display: block;
      margin: 5rem auto;
    }
  }
  .line {
    border: 1px solid #ccc;
  }
  .history {
    img {
      width: 100%;
    }
  }
  .funs {
    padding: 7rem;
    position: relative;
    .fun {
      background: #8f0001;
      position: relative;
      z-index: 20;
      border-radius: 1rem;
      padding: 7rem 10rem;
      li {
        text-align: center;
        font-size: 2rem;
        color: #fff;
        width: 18rem;
        img {
          border: 4px solid #fff;
          border-radius: 50%;
          width: 100%;
          box-sizing: border-box;
          object-fit: cover;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .skill {
    li {
      width: 36.6rem;
      text-align: center;
      margin-bottom: 3rem;
      img {
        width: 100%;
        height: 26.8rem;
        object-fit: cover;
        margin-bottom: 1.8rem;
      }
      p {
        font-size: 1.8rem;
        color: #666666;
      }
    }
  }
  .his-item {
    position: relative;
    &:after {
      height: 0;
      width: 100%;
      content: "";
      border-top: 1px solid #e0e0e0;
      position: absolute;
      left: 0;
      top: 50%;
    }
    .flex {
      -webkit-box-align: start;
      align-items: flex-start;
      position: relative;
      z-index: 2;
      &.sp {
        -webkit-box-align: end;
        align-items: flex-end;
        left: 50%;
        margin-top: -25px;
      }
      .his-time {
        text-align: center;
        & > span {
          width: 8rem;
          border-radius: 50%;
          background: #8f0001;
          font-size: 3rem;
          border: 1rem solid #e0e0e0;
          line-height: 8rem;
          display: block;
          color: #fff;
          text-align: center;
          white-space: nowrap;
        }
        & > img {
          width: auto;
        }
      }
      .his-text {
        padding: 3% 10%;
        flex: 1;
        font-size: 1.8rem;
        color: #666666;
        .his-dt {
          font-size: 2.4rem;
          color: #111;
          position: relative;
          margin-bottom: 0.8rem;
          &:after {
            width: 1.2rem;
            height: 1.2rem;
            background: #8f0001;
            content: "";
            position: absolute;
            left: -2.3rem;
            top: 50%;
            margin-top: -0.6rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .funs {
      padding: 5%;
      .fun {
        padding: 4%;
        li {
          width: 10rem;
          margin: 2rem 0;
        }
      }
    }
    .skill li {
      width: 49%;
      img {
        height: 18rem;
      }
    }
  }
}
</style>
