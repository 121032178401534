<template>
  <div class="detail">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="fadeInUp">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="description">
            <div class="title">{{common.detail.title}}</div>
            <div class="timer">发表于：{{common.detail.add_time}}</div>
          </div>
          <div class="content" v-html="common.detail.info">
            {{common.detail.info}}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "detail",
  props: {
    msg: String,
  },
  data() {
    return {
		common: [],
      height: 0,
      now: 0,
    };
  },
  mounted() {
this.$http.post('news_detail.php',Qs.stringify({data_type:'common', detail_id:this.$route.query.detail_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 4) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.detail {
  .description {
    text-align: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 4.5rem;
    .title {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    .time {
      font-size: 1.6rem;
      color: #555555;
    }
  }
  .content {
    font-size: 1.8rem;
    line-height: 3rem;
    color: #555555;
    text-indent: 2rem;
    img {
      display: block;
      margin: 5rem auto;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 640px) {
    .content {
      font-size: 3rem;
      line-height: 1.5;
    }
  }
}
</style>
