<template>
  <div class="case">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="bounceIn">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="home">
            <h3>{{common.s1.title}}</h3>
            <p>{{common.s1.stitle}}</p>
          </div>
          <div class="beyond">
            <swiper :options="swiperOption">
              <swiper-slide class="swiper-slide" v-for="(item, index) in common.s1.img" :key="index">
                <img v-bind:src="item.img" style="width: 100%" alt="" />
              </swiper-slide>
            </swiper>
            <!-- 左右箭头 -->
            <div class="beyond-nav flex center">
              <div
                class="iconfont icon-left beyond-nav1"
                slot="button-prev"
              ></div>
              <div class="flex center middle">
                <div class="beyond-pagination flex wrap center"></div>
              </div>
              <div
                class="iconfont icon-you beyond-nav2"
                slot="button-next"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fadeInUp">
    <template  v-for="(item, index) in common.s2.img">
      <img v-if="index < 1" :key="index"
        v-bind:src="item.img"
        v-show="now > 2"
        style="width: 100%"
        alt=""
      />
    </template>
    </transition>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 3">
        <div class="cont">
          <div class="home">
            <h3>{{common.s3.title}}</h3>
            <p>{{common.s3.stitle}}</p>
          </div>
          <ul class="flex wrap case-list">
            <li v-for="(item, index) in common.s3.img" :key="index">
              <img v-bind:src="item.img" alt="" />
              <p class="nowrap">{{item.name}}</p>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "case",
  props: {
    msg: String,
  },
  data() {
    return {
      common: [],
      height: 0,
      now: 0,
      swiperOption: {
        loop: true,
        pagination: {
          el: ".beyond-pagination",
        },
        navigation: {
          nextEl: ".beyond-nav1",
          prevEl: ".beyond-nav2",
        },
      },
    };
  },
  mounted() {
this.$http.post('statics.php',Qs.stringify({data_type:'common', type_id:this.$route.query.type_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 6) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.case {
  .case-list {
    li {
      width: 49.3rem;
      margin-bottom: 2rem;
      position: relative;
      &:first-child,
      &:first-child + li {
        width: 75rem;
        & > img {
          height: 41.1rem;
        }
      }
      & > img {
        width: 100%;
        height: 27rem;
        object-fit: cover;
      }
      p {
        position: absolute;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4rem;
        font-size: 2.4rem;
        line-height: 1;
        bottom: 0;
        color: #fff;
        background: linear-gradient(0, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
        &:before {
          height: 2.4rem;
          width: 3px;
          background: #890708;
          display: inline-block;
          margin-right: 2.2rem;
          vertical-align: top;
          content: "";
        }
      }
    }
  }
  .beyond-nav {
    padding: 2rem 0;
    & > .flex {
      background: #f5f5f5;
      width: 3rem;
      padding: 0 2rem;
      .beyond-pagination {
        /deep/ .swiper-pagination-bullet {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: #999;
          opacity: 1;
          margin: 0.2rem;
          &.swiper-pagination-bullet-active {
            background: #8f0001;
          }
        }
      }
    }

    .iconfont {
      width: 4.8rem;
      height: 4.8rem;
      line-height: 4.8rem;
      text-align: center;
      position: relative;
      font-size: 2rem;
      margin: 0 0.5rem;
      background: #fff;
      transition: all 0.3s;

      &:hover {
        background: #8f0001;
        color: #fff;
      }
      &:after {
        border: 1px solid #333;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .case-list {
      li {
        width: 49% !important;
        img {
          height: 15rem !important;
        }
        p {
          padding: 4%;
        }
      }
    }
  }
}
</style>
