<template>
  <div class="door">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>

    <transition name="fadeInLeft">
      <div class="bg" v-show="now > 1">
        <div class="cont" id='x'>
          <div class="home">
            <h3>木门系列</h3>
            <p>帝彤木门 -------- 开启健康，享受生活</p>
          </div>
          <div class="kind">
            <a href="javascript:;" :class="dactiveClass == index ? 'active':''" v-for="(item,index) in common.door_type" :key="index" @click="door_type(item.id); click_door_type(index);">{{item.name}}</a>
          </div>
          <ul class="list wrap flex">
            <li
              v-for="(item, index) in common.door"
              :key="index"
              @click="onImgClick(true, index)"
            >
              <img v-bind:src="item.img" class="door-img" alt="" />
              <div class="door-name flex nowrap">
                型号：{{item.name}}<span
                  class="iconfont icon-fangdajing"
                ></span>
              </div>
            </li>
          </ul>
          <div class="pagination flex center">
            <a href="javascript:;" @click="page('door_page', 'up');"><span class="iconfont icon-left"></a>
            <a href="javascript:;" v-for="(item,index) in common.door_page" :key="index" @click="page('door_page', item.p);">{{item.p_name}}</a>
            <a href="javascript:;" @click="page('door_page', 'down');"><span class="iconfont icon-you"></a>
          </div>
          <input type="hidden" ref="door_page" v-model="common.d_n_p">
          <input type="hidden" ref="door_type" v-model="common.d_n_t">
        </div>
      </div>
    </transition>
    <div class="line"></div>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 2">
        <div class="cont">
          <div class="home">
            <h3>整装系列</h3>
            <p>帝彤木门 -------- 装扮精彩，点亮未来</p>
          </div>
          <div class="kind">
            <a href="javascript:;" :class="wactiveClass == index ? 'active':''" v-for="(item,index) in common.whole_type" :key="index" @click="whole_type(item.id); click_whole_type(index)">{{item.name}}</a>
          </div>
          <ul class="list wrap flex">
            <li
              v-for="(item, index) in common.whole"
              :key="index"
              @click="onImgClick(false, index)"
            >
              <img v-bind:src="item.img" class="door-img" alt="" />
              <div class="door-name flex nowrap">
                型号：{{item.name}}<span
                  class="iconfont icon-fangdajing"
                ></span>
              </div>
            </li>
          </ul>
          <div class="pagination flex center">
            <a href="javascript:;" @click="page('whole_page', 'up');"><span class="iconfont icon-left"></a>
            <a href="javascript:;" v-for="(item,index) in common.whole_page" :key="index" @click="page('whole_page', item.p);">{{item.p_name}}</a>
            <a href="javascript:;" @click="page('whole_page', 'down');"><span class="iconfont icon-you"></a>
          </div>
          <input type="hidden" ref="whole_page" v-model="common.w_n_p">
          <input type="hidden" ref="whole_type" v-model="common.w_n_t">
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div
        class="modal flex middle center"
        v-show="show"
        @click.self="show = false"
      >
        <div class="gallerys">
          <swiper :options="swiperOption" ref="swiper">
            <swiper-slide
              class="gallery"
              v-for="(item, index) in isWood ? common.door_b : common.whole_b"
              :key="index"
            >
              <img v-bind:src="item.img" style="" alt="" />
              <div class="gallery-info">
                <div class="flex">
                  <div><span>产品系列：</span>{{item.type_name}}</div>
                  <div><span>产品型号：</span>{{item.name}}</div>
                  <div><span>装修风格：</span>{{item.style}}</div>
                </div>
                <div class="flex">
                  <div><span>可选颜色：</span>{{item.color}}</div>
                </div>
                <div class="flex">
                  <div>
                    <span>产品描述：</span
                    >{{item.info}}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="iconfont icon-left" slot="button-prev"></div>
          <div class="iconfont icon-you" slot="button-next"></div>
          <div class="close" @click.self="show = false">&times;</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "home",
  props: {
    msg: String,
  },
  data() {
    return {
		dactiveClass: 0,
		wactiveClass: 0,
      height: 0,
      now: 0,
      show: false,
      common:[],
      isWood: true,
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".icon-left",
          prevEl: ".icon-you",
        },
      },
    };
  },
  mounted() {
this.$http.post('product.php',Qs.stringify({data_type:'common'}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 4) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
door_data(id, page){
	this.$http.post('product.php',Qs.stringify({data_type:'dtype', id:id, page:page}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
		this.common.door=response.data.door;
		this.common.door_b=response.data.door_b;
		this.common.door_page=response.data.door_page;
		this.common.d_n_p=response.data.d_n_p;
	})
},
whole_data(id, page){
	this.$http.post('product.php',Qs.stringify({data_type:'wtype', id:id, page:page}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
		this.common.whole=response.data.whole;
		this.common.whole_b=response.data.whole_b;
		this.common.whole_page=response.data.whole_page;
		this.common.w_n_p=response.data.w_n_p;
	})
},
door_type(id) {
	this.common.d_n_t=id;
	this.$options.methods.door_data.bind(this)(id, 1);
},
click_door_type(index){
	this.dactiveClass = index;
},
whole_type(id) {
	this.common.w_n_t=id;
	this.$options.methods.whole_data.bind(this)(id, 1);
},
click_whole_type(index){
	this.wactiveClass = index;
},
page(type_name, page){
	if (type_name=='door_page'){
		this.common.d_n_p=parseInt(this.common.d_n_p);
		if (page=='up'){
			page=this.common.d_n_p-1;
		}
		if (page=='down'){
			page=this.common.d_n_p+1;
		}
		this.$refs.door_page.value=page;
		this.$options.methods.door_data.bind(this)(this.common.d_n_t, page);
	}
	else{
		this.common.w_n_p=parseInt(this.common.w_n_p);
		if (page=='up'){
			page=this.common.w_n_p-1;
		}
		if (page=='down'){
			page=this.common.w_n_p+1;
		}
		this.$refs.whole_page.value=page;
		this.$options.methods.whole_data.bind(this)(this.common.w_n_t, page);
	}
},
    onImgClick(status, ind) {
      this.isWood = status;
      this.show = true;
      this.$refs.swiper.$swiper.slideTo(ind, 1000, false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.door {
  .list {
    li {
      width: 48.6rem;
      margin-bottom: 3rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #8f0001;
        color: #fff;
      }
      .door-img {
        width: 100%;
        height: 36.5rem;
        object-fit: cover;
      }
      .door-name {
        padding: 0 2rem;
        line-height: 5.8rem;
        font-size: 2rem;
        border: 1px solid #cccccc;
        border-top: none;
        font-size: 1.8rem;
        .iconfont {
          font-size: 2.8rem;
        }
      }
    }
  }

  .line {
    border-top: 1px solid #ccc;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    overflow-y: auto;
    z-index: 300;
    .gallerys {
      padding: 2rem;
      background: #fff;
      width: 145rem;
      position: relative;
      top: 10rem;
      margin: 5rem 5rem;
      .gallery-info {
        padding: 3rem 3rem 1rem;
        .flex {
          font-size: 2.4rem;
          line-height: 3rem;
          padding: 1rem 0;
          span {
            color: #8f0001;
          }
        }
      }
      & > .close {
        position: absolute;
        right: 0;
        top: -8rem;
        color: #fff;
        font-size: 8rem;
        font-weight: 200;
        line-height: 1;
      }
      .icon-left,
      .icon-you {
        width: 4.8rem;
        height: 4.8rem;
        border: 1px solid #fff;
        color: #fff;
        font-size: 2rem;
        text-align: center;
        line-height: 4.8rem;
        position: absolute;
        bottom: 0;
        left: -5rem;
        cursor: pointer;
        &:hover {
          background: #8f0001;
          border-color: #8f0001;
        }
        &.icon-you {
          left: auto;
          right: -5rem;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .list li {
      width: 49%;
      .door-img {
        height: 18rem;
      }
      .door-name .iconfont {
        display: none;
      }
    }
    .modal > .gallerys {
      width: 100%;
      padding: 1%;
      top: 7rem;
      box-sizing: border-box;
      .icon-left {
        left: 30%;
        bottom: -6rem;
      }
      .icon-you.icon-you {
        right: 30%;
        bottom: -6rem;
      }
    }
  }
}
</style>
