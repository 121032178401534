<template>
  <div class="contact">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="fadeInRight">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="home">
            <h3>{{common.s1.title}}</h3>
            <p>{{common.s1.stitle}}</p>
          </div>
          <div v-html="common.s1.info">{{common.s1.info}}</div>
          <div class="long"></div>
          <div class="home">
            <h3>{{common.s2.title}}</h3>
            <p>{{common.s2.stitle}}</p>
          </div>
          <div v-html="common.s2.info">{{common.s2.info}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "contact",
  props: {
    msg: String,
  },
  data() {
    return {
		common: [],
      height: 0,
      now: 0,
    };
  },
  mounted() {
this.$http.post('statics.php',Qs.stringify({data_type:'common', type_id:this.$route.query.type_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 6) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.contact {
  .way {
    li {
      width: 48.6rem;
      height: 53.4rem;
      border: 1px solid #ccc;
      text-align: center;
      h5 {
        font-size: 4.8rem;
        margin-top: 4rem;
        color: #900000;
        &.sp {
          font-size: 3rem;
        }
        &::after {
          width: 4.5rem;
          height: 4px;
          content: "";
          display: block;
          background: #900000;
          margin: 3rem auto 0;
        }
      }
      p {
        margin-top: 9.5rem;
        font-size: 2.4rem;
      }
      .coding {
        img {
          max-width: 32rem;
        }
        p {
          margin-top: 3rem;
        }
      }
    }
  }
  .table {
    background: #fff;
    thead {
      background: #8f0001;
      th {
        font-size: 2.2rem;
        line-height: 8rem;
        color: #fff;
        text-align: center;
      }
    }
    tbody {
      td {
        border-top: 1px solid #ccc;
        font-size: 1.8rem;
        line-height: 2rem;
        padding: 2.1rem 0;
        text-align: center;
        .iconfont {
          font-size: 3rem;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .way li {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
</style>
