<template>
  <div class="join">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>

    <transition name="fadeInLeft">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <div class="home">
            <h3>{{common.s1.title}}</h3>
            <p>{{common.s1.stitle}}</p>
          </div>
          <ul class="good flex wrap">
            <li v-for="(item, index) in common.s1.img" :key="index">
              <img v-bind:src="item.img" alt="" />
              <p class="nowrap">{{item.name}}</p>
            </li>
          </ul>
          <div class="long"></div>

          <transition name="fadeInRight">
            <div v-show="now > 2">
              <div class="home">
                <h3>{{common.s2.title}}</h3>
                <p>{{common.s2.stitle}}</p>
              </div>
              <ul class="area flex">
                <li v-for="(item, index) in common.s2.img" :key="index">
                  <div class="area-info">
                    <b>{{item.name}}</b>
                    <p v-html="item.other">
                      {{item.other}}
                    </p>
                  </div>
                  <img v-bind:src="item.img" alt="" />
                </li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fadeInUp">
      <div class="bg red" v-show="now > 3">
        <div class="cont">
          <dl class="caip">
            <dt>{{common.s3.title}}</dt>
            <dd>
              {{common.s3.stitle}}
            </dd>
          </dl>
          <div v-html="common.s3.info">{{common.s3.info}}</div>
        </div>
      </div>
    </transition>

    <transition name="fadeInRight">
      <div class="cont" v-show="now > 4">
        <div class="home">
          <h3>{{common.s4.title}}</h3>
          <p>{{common.s4.stitle}}</p>
        </div>
        <div class="serve">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="serve-info">
                <div class="serve-dt">0{{ index + 1 }}</div>
                <div class="serve-dd">{{ item.name }}</div>
                <div class="serve-img">
                  <img :src="item.img" class="sp" alt="" />
                  <img :src="item.img2" alt="" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="serve-item"
            :style="{ width: ((pindex + 1) / (list.length || 1)) * 100 + '%' }"
          ></div>
        </div>
      </div>
    </transition>

    <transition name="fadeInLeft">
      <div class="bg" v-show="now > 5">
        <div class="cont">
          <div class="home">
            <h3>{{common.s5.title}}</h3>
            <p>{{common.s5.stitle}}</p>
          </div>
          <ul class="need flex">
            <li v-for="(item, index) in common.s5.img" :key="index">
              <p>{{item.name}}</p>
              <img v-bind:src="item.img" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </transition>

    <transition name="fadeInUp">
      <div class="bg" v-show="now > 6">
        <img src="@/assets/coco.jpg" class="bg-img" alt="" />
        <div class="cont">
          <div class="home white">
            <h3>{{common.s6.title}}</h3>
            <p>{{common.s6.stitle}}</p>
          </div>
          <ul class="flex wrap coco">
            <li class="flex center middle" v-for="(item, index) in common.s6.img" :key="index">
              <img v-bind:src="item.img" alt="" />{{item.name}}
            </li>
          </ul>
          <div class="long"></div>
          <div class="home white">
            <h3>{{common.s7.title}}</h3>
          </div>
          <div v-html="common.s7.info">{{common.s7.info}}</div>
        </div>
      </div>
    </transition>

    <transition name="bounceIn">
      <div class="bg" v-show="now > 7">
        <div class="cont">
          <div class="home">
            <h3>{{common.s8.title}}</h3>
            <p>{{common.s8.stitle}}</p>
          </div>
          <ul class="play flex wrap">
            <li v-for="(item, index) in common.s8.img" :key="index"><img v-bind:src="item.img" alt="" /></li>
          </ul>
          <div class="long"></div>
          <div class="home">
            <h3>创未来，等你来</h3>
            <p>在线加盟</p>
          </div>
          <transition name="bounceInRight">
            <form class="flex wrap form" v-show="now > 8">
              <v-distpicker></v-distpicker>

              <div class="form-item sp">
                <img src="@/assets/cc2.png" alt="" />
                <input type="text" placeholder="请输入您的姓名" />
              </div>
              <div class="form-item sp">
                <img src="@/assets/cc3.png" alt="" />
                <input type="text" placeholder="请输入您的电话号码" />
              </div>
              <textarea placeholder="备注"></textarea>
              <div class="flex end submit">
                <a href="#" class="btn">提交信息</a
                ><a href="#" class="btn2">重置信息</a>
              </div>
            </form>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import Qs from 'qs';

export default {
  name: "join",
  components: { VDistpicker },
  props: {
    msg: String,
  },
  data() {
    const that = this;
    return {
		common: [],
      height: 0,
      now: 0,
      pindex: 0,
      swiperOption: {
        slidesPerView: "4",
        on: {
          slideChangeTransitionEnd: function () {
            that.pindex = this.activeIndex;
          },
        },
        breakpointsInverse: true,
        breakpoints: {
          //当宽度大于等于320
          320: {
            slidesPerView: 3,
          },
          //当宽度大于等于480
          480: {
            slidesPerView: 3,
          },
          //当宽度大于等于640
          640: {
            slidesPerView: 4,
          },
        },
      },
      list: [
        {
          name: "免费上门量尺",
          img: require("../assets/sm1.png"),
          img2: require("../assets/sm1a.png"),
        },
        {
          name: "免费送货安装",
          img: require("../assets/sm2.png"),
          img2: require("../assets/sm2a.png"),
        },
        {
          name: "全程顾问式接待服务",
          img: require("../assets/sm3.png"),
          img2: require("../assets/sm3a.png"),
        },
        {
          name: "售后极速响应",
          img: require("../assets/sm4.png"),
          img2: require("../assets/sm4a.png"),
        },
        {
          name: "售后极速响应",
          img: require("../assets/sm4.png"),
          img2: require("../assets/sm4a.png"),
        },
      ],
    };
  },
  mounted() {
this.$http.post('statics.php',Qs.stringify({data_type:'common', type_id:this.$route.query.type_id}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 10) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.join {
  .good {
    li {
      width: 48.6rem;
      position: relative;
      margin-bottom: 3rem;
      img {
        width: 100%;
        height: 32.2rem;
        object-fit: cover;
      }
      p {
        position: absolute;
        font-size: 2rem;
        line-height: 5rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        left: 0;
        width: 100%;
        bottom: 0;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
  .area {
    li {
      width: 74.5rem;
      & > img {
        width: 100%;
        height: 49.4rem;
        object-fit: cover;
      }
      .area-info {
        border: 1px solid #ccc;
        border-bottom: none;
        text-align: center;
        padding: 4.5rem 0 2.5rem;
        box-sizing: border-box;
        b {
          font-size: 3.6rem;
          display: block;
          margin-bottom: 2rem;
        }
        p {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
    }
  }

  .red {
    position: relative;
    &::after {
      width: 100%;
      height: 75%;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: #900000;
    }
    .cont {
      z-index: 22;
      .caip {
        color: #fff;
        margin-bottom: 3.5rem;
        dt {
          font-size: 4.8rem;
          margin-bottom: 0.5rem;
        }
        dd {
          font-size: 2rem;
        }
      }
    }
  }

  .serve {
    padding-bottom: 3rem;
    border-bottom: 4px solid #cccccc;
    position: relative;
    .serve-info {
      padding: 3.5rem 6rem 9.5rem;
      .serve-dt {
        font-size: 2rem;
      }
      .serve-dd {
        font-size: 2.4rem;
        margin: 2.5rem 0 3rem;
      }
      .serve-img {
        img {
          max-width: 25%;
        }
        img.sp {
          display: none;
        }
      }
      &:hover {
        background: #900000;
        color: #fff;
        .serve-img {
          img {
            display: none;
            &.sp {
              display: block;
            }
          }
        }
      }
    }
    .serve-item {
      height: 4px;
      background: #900000;
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 25%;
    }
  }
  .need {
    li {
      width: 35.8rem;
      p {
        text-align: center;
        font-size: 2rem;
        line-height: 7.9rem;
        border: 1px solid #ccc;
        border-bottom: none;
      }
      & > img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
      }
    }
  }
  .step {
    background: #fff;
    li {
      flex: 1;
      height: 14.2rem;
      font-size: 2rem;
      -webkit-box-orient: vertical;
      flex-direction: column;
      position: relative;
      img {
        max-width: 30%;
      }
      &:hover {
        color: #900000;
        img {
          display: none;
        }
        img.sp {
          display: inline;
        }
      }
      &:after {
        top: 20%;
        bottom: 20%;
        left: 0;
        content: "";
        border-left: 1px solid #ddd;
        position: absolute;
      }
      img.sp {
        display: none;
      }
      p {
        margin-top: 1.5rem;
      }
    }
  }
  .form {
    /deep/.distpicker-address-wrapper {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1rem;
      label {
        position: relative;
        width: 49rem;
        &:before {
          width: 6rem;
          height: 6rem;
          content: "";
          background: url(../assets/cc1.png) no-repeat center center;
          background-size: 3rem;
          position: absolute;
          top: 0;
          left: 1rem;
        }
        select {
          border: 1px solid #ccc;
          height: 6rem;
          line-height: 6rem;
          width: 100%;
          font-size: 1.8rem;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 6.8rem;
          box-sizing: border-box;
          background: url(../assets/sel.png) no-repeat 90% center;
          appearance: none;
        }
      }
    }
    .form-item {
      width: 74.8rem;
      position: relative;
      margin-bottom: 1rem;
      input,
      select {
        border: 1px solid #ccc;
        height: 6rem;
        line-height: 6rem;
        width: 100%;
        padding-left: 6.8rem;
        box-sizing: border-box;
        font-size: 1.8rem;
      }
      select {
        background: url(../assets/sel.png) no-repeat 90% center;
        appearance: none;
      }
      img {
        position: absolute;
        left: 20px;
        top: 50%;
        max-height: 3rem;
        transform: translateY(-50%);
      }
    }
    textarea {
      border: 1px solid #ccc;
      height: 28rem;
      line-height: 3rem;
      width: 100%;
      padding: 2rem 2.5rem;
      box-sizing: border-box;
      resize: none;
    }
    .submit {
      width: 100%;
      margin-top: 4rem;
      a {
        width: 21.6rem;
        line-height: 6rem;
        font-size: 2rem;
        text-align: center;
        color: #fff;
        background: #900000;
        margin-left: 30px;
        &.btn2 {
          background: #999999;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .good,
    .area {
      li {
        width: 49%;
        img {
          height: 16rem;
        }
      }
    }
    .serve .serve-info {
      padding: 3%;
    }
    .need li {
      width: 49%;
      & > img {
        height: 16rem;
      }
    }
    .step {
      -webkit-box-pack: center;
      justify-content: center;
      padding: 4rem 0;
      li {
        flex: 15rem 0 0;
        margin: 2rem 0;
        flex-wrap: unset;
        &:after {
          display: none;
        }
        p {
          width: 100%;
          text-align: center;
        }
      }
    }
    .form {
      /deep/.distpicker-address-wrapper {
        -webkit-box-lines: multiple;
        flex-wrap: wrap;
        label {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
      .form-item {
        width: 100%;
        img {
          left: 3%;
        }
      }
      .submit {
        a {
          width: 100%;
          margin-left: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
