<template>
  <div class="voice">
    <transition name="fadeInUp">
      <div class="banner" v-show="now > 0">
        <img v-bind:src="common.banner.img" class="banner-img" alt="" />
        <div class="banner-title flex middle center">
          <img v-bind:src="common.banner.p" alt="" />
        </div>
      </div>
    </transition>
    <transition name="fadeInUp">
      <div class="bg" v-show="now > 1">
        <div class="cont">
          <ul class="flex video wrap">
            <li
              v-for="(item, index) in common.video"
              :key="index"
              @click="onImgClick(item)"
            >
              <img v-bind:src="item.img" alt="" />
              <div class="video-dt nowrap">
                {{item.name}}
              </div>
              <div class="video-dd nowrap">帝彤木门：你的品质之选</div>
            </li>
          </ul>
          <div class="pagination flex center">
            <a href="javascript:;" @click="page('v_page', 'up');"><span class="iconfont icon-left"></a>
            <a href="javascript:;" v-for="(item,index) in common.v_page" :key="index" @click="page('v_page', item.p);">{{item.p_name}}</a>
            <a href="javascript:;" @click="page('v_page', 'down');"><span class="iconfont icon-you"></a>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fadeInUp">
      <div class="bg" v-show="now > 2">
        <div class="cont">
          <ul class="flex phone">
            <li
              v-for="(item, index) in common.phone"
              :key="index"
              @click="onImgClick(item)"
            >
              <img v-bind:src="item.img" class="phone-img" alt="" />
            </li>
          </ul>
          <div class="pagination flex center">
            <a href="javascript:;" @click="page('p_page', 'up');"><span class="iconfont icon-left"></a>
            <a href="javascript:;" v-for="(item,index) in common.p_page" :key="index" @click="page('p_page', item.p);">{{item.p_name}}</a>
            <a href="javascript:;" @click="page('p_page', 'down');"><span class="iconfont icon-you"></a>
          </div>
        </div>
      </div>
    </transition>
    <transition name="bounce">
      <div
        class="modal flex middle center"
        v-if="show"
        @click.self="show = false"
      >
        <div class="video">
          <!-- 左右箭头 -->
          <iframe
            v-bind:src="url" ref="url"
            border="false"
          />
          <div class="close" @click.self="show = false">&times;</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Qs from 'qs';
export default {
  name: "voice",
  props: {
    msg: String,
  },
  data() {
    return {
		common:[],
      height: 0,
      now: 0,
      show: false,
      url: '',
    };
  },
  mounted() {
this.$http.post('video.php',Qs.stringify({data_type:'common'}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
	this.common=response.data;
	document.title=response.data.web_title;
})
    this.$nextTick(() => {
      this.now++;
      this.height =
        document.documentElement.clientHeight +
          30 +
          document.getElementById("footer").clientHeight ||
        document.body.clientHeight +
          30 +
          document.getElementById("footer").clientHeight;
      window.addEventListener("scroll", this.scrolling, true);
      this.$nextTick(() => {
        if (
          this.height >
          (document.documentElement.scrollHeight || document.body.scrollHeight)
        ) {
          this.scrolling();
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      if (this.now < 6) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + this.height > scrollHeight) {
          this.now++;
          return false;
        }
      }
    },

v_data(page){
	this.$http.post('video.php',Qs.stringify({data_type:'vtype', page:page}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
		this.common.video=response.data.video;
		this.common.v_page=response.data.v_page;
		this.common.v_n_p=response.data.v_n_p;
	})
},
p_data(page){
	this.$http.post('video.php',Qs.stringify({data_type:'ptype', page:page}),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
		this.common.phone=response.data.phone;
		this.common.p_page=response.data.p_page;
		this.common.p_n_p=response.data.p_n_p;
	})
},
page(type_name, page){
	if (type_name=='v_page'){
		this.common.v_n_p=parseInt(this.common.v_n_p);
		if (page=='up'){
			page=this.common.v_n_p-1;
		}
		if (page=='down'){
			page=this.common.v_n_p+1;
		}
		this.$options.methods.v_data.bind(this)(page);
	}
	else{
		this.common.p_n_p=parseInt(this.common.p_n_p);
		if (page=='up'){
			page=this.common.p_n_p-1;
		}
		if (page=='down'){
			page=this.common.p_n_p+1;
		}
		this.$options.methods.p_data.bind(this)(page);
	}
},
    onImgClick(item) {
    this.url=item.url;
      this.show = true;
      console.log(item);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.voice {
  .video {
    padding-left: 91.4rem;
    position: relative;
    margin-bottom: 7rem;
    li {
      width: 29.3rem;
      margin-bottom: 3rem;
      &:first-child {
        width: 89.6rem;
        position: absolute;
        top: 0;
        left: 0;
        & > img {
          height: 50.4rem;
          margin-bottom: 2.4rem;
        }
      }
      & > img {
        width: 100%;
        height: 16.5rem;
        object-fit: cover;
        margin-bottom: 1.1rem;
      }
      .video-dt {
        font-size: 2.4rem;
        line-height: 1.5;
      }
      .video-dd {
        font-size: 1.8rem;
        color: #666666;
        line-height: 1.5;
      }
    }
  }
  .phone {
    li {
      width: 28rem;
      margin-bottom: 3rem;
      .phone-img {
        width: 28rem;
        height: 49.4rem;
        object-fit: cover;
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    overflow-y: auto;
    z-index: 300;
    .video {
      padding: 2rem;
      background: #fff;
      width: 145rem;
      position: relative;
      top: 5rem;
      margin: 5rem 5rem;
      iframe {
        width: 100%;
        height: 88rem;
      }
      & > .close {
        position: absolute;
        right: 0;
        top: -8rem;
        color: #fff;
        font-size: 8rem;
        font-weight: 200;
        line-height: 1;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .video {
      padding-left: 0;
      margin-bottom: 0;
      li {
        &:first-child {
          width: 100%;
          position: relative;
          img {
            height: 28rem;
          }
        }
        width: 49%;
        img {
          height: 15rem;
        }
      }
    }
    .phone {
      li {
        width: 49%;
        .phone-img {
          width: 100%;
          height: 40rem;
        }
      }
    }
  }
}
</style>
